import React from 'react';
import { BusinessObjectStatistic } from '../../../../../models/interfaces/BusinessObjectStatistic.interface';
import {
  CustomDataTableConfig,
  HeadCell,
} from '../../../../../commons/components/custom-data-table/custom-data-table-config.model';
import CustomDataTableComponent from '../../../../../commons/components/custom-data-table/custom-data-table.component';
import { LAST_BO_ID_WITH_OLD_PRO_PRICE } from '../../../../../models/consts/Other.const';

interface BusinessObjectStatisticTableComponentProps {
  businessObjectStatistics: BusinessObjectStatistic[];
  onSelect?: Function;
}

export default function BusinessObjectStatisticTableComponent(props: BusinessObjectStatisticTableComponentProps) {
  const isInvoiceInfoRequired = (item: BusinessObjectStatistic): boolean => {
    if (item.boId <= LAST_BO_ID_WITH_OLD_PRO_PRICE) {
      return false;
    }

    return item?.monthlyFee > 0 && !item.invoiceJobExisted;
  };

  const headCells: HeadCell[] = [
    { id: 'boId', field: 'boId', label: 'Salon ID' },
    { id: 'name', field: 'name', label: 'Salon name' },
    { id: 'owner', field: 'owner', label: 'Owner' },
    { id: 'numberOfSentSms', field: 'numberOfSentSms', label: 'Nr sent SMS' },
    { id: 'numberOfSentEmail', field: 'numberOfSentEmail', label: 'Nr sent Email' },
    { id: 'numberOfAppointments', field: 'numberOfAppointments', label: 'Nr appointments' },
    {
      id: 'numberOfNewAppointments',
      field: 'numberOfNewAppointments',
      label: 'Nr appointments of new/180 days returned customer',
    },
    { id: 'numberOfOnlineBPAppointments', field: 'numberOfOnlineBPAppointments', label: 'Nr BP appointments' },
    {
      id: 'numberOfOnlineBPAppointmentsBoCanceled',
      field: 'numberOfOnlineBPAppointmentsBoCanceled',
      label: 'Nr BP appointments canceled by salon',
    },
    {
      id: 'numberOfOnlineBPAppointmentsCustomerCanceled',
      field: 'numberOfOnlineBPAppointmentsCustomerCanceled',
      label: 'Nr BP appointments canceled by customer',
    },
    { id: 'numberOfNewCustomers', field: 'numberOfNewCustomers', label: 'Nr new customers' },
    { id: 'numberOfOrders', field: 'numberOfOrders', label: 'Nr orders' },
    { id: 'monthlyFee', field: 'monthlyFee', label: 'Fee' },
    { id: 'priceDescription', field: 'priceDescription', label: 'Price description' },
    {
      id: 'invoiceJobExisted',
      label: 'Invoice job existed',
      getFieldValueFunc: (item: BusinessObjectStatistic) => (item.invoiceJobExisted ? 'yes' : 'no'),
    },
    {
      id: 'isInvoiceInfoRequired',
      label: 'Invoice info required',
      getFieldValueFunc: (item: BusinessObjectStatistic) => (isInvoiceInfoRequired(item) ? 'yes' : 'no'),
    },
  ];

  const tableConfig: CustomDataTableConfig = {
    tableName: `Business object statistic`,
    rowsPerPage: 10,
    headCells,
    highlightRowColorFunc: (item: BusinessObjectStatistic): string | null => {
      if (isInvoiceInfoRequired(item)) {
        return 'red';
      }

      if (item?.monthlyFee > 0) {
        return '#72E1A3';
      }

      return null;
    },
  };

  return (
    <CustomDataTableComponent
      tableConfig={tableConfig}
      data={props?.businessObjectStatistics}
      onSelect={props?.onSelect}
    />
  );
}
